import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Pc, FieldProps } from '../types';
import Aside from './Aside';
import MyImageInput from '../inputs/MyImageInput';
import { BooleanInput } from 'react-admin';

const SlideEdit = (props: any) => {
    return (
        <Edit
            title={<PcTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <SlideForm />
        </Edit>
    );
};

const PcTitle: FC<FieldProps<Pc>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const SlideForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(30)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        source="name"
                                        resource="slides"
                                        label="resources.slides.fields.name"
                                        validate={validateName}
                                        fullWidth
                                    />
                                    <MyImageInput
                                        source="image"
                                        label="resources.slides.fields.image"
                                        validate={[required()]}
                                    />
                                    <BooleanInput
                                        source="is_enabled"
                                        label="resources.slides.fields.is_enabled"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="slides"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default SlideEdit;
