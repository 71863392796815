import React, { useState } from 'react';
import { useMutation, useRefresh } from 'react-admin';
import { Button, CircularProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(-1),
        float: 'right',
    }
}));


const SaveButtonWithEndpoint = ({ record, division }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [mutate] = useMutation();
    const classes = useStyles();
    const history = useHistory();
    const refresh = useRefresh();
    const notify = useNotify();


    const handleClick = async () => {
        try {
            setIsLoading(true);
            const response = await mutate({
                type: 'create',
                resource: `orders/${record.id}/assign`,
                payload: {
                    data: {
                        livreur: division
                    },
                }
            }, 
            {
                onSuccess: (result) => {
                    notify('La commande a été assigné livreur', 'success');
                },
                onFailure: (error) => {
                    notify(`Error: ${error.message}`, 'error');
                },
            })
            refresh();
            history.push(`/orders/${record.id}/show`);

            if (response && response?.status === 200) {
                notify('Assign Success', 'info');
            } else if (response && response?.status === 400) {
                const error = await response?.json();
                notify(`Error: ${error?.message}`, 'warning');
            }
        } catch (error: any) {
            console.error(error);
            notify(`Error: ${error.message}`, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box className={classes.root}>
            <Button variant="contained" color="primary" onClick={handleClick} >
                {isLoading ? <CircularProgress size={25} /> : 'Save'}
            </Button>
        </Box>
    );
};

export default SaveButtonWithEndpoint;
