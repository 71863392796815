import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import List from './VehiculeTypeList';
import Edit from './VehiculeTypeEdit';
import Create from './VehiculeTypeCreate';

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: LocalShippingIcon,
};
