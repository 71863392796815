import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
const useStyle = makeStyles(theme => ({
    code: {
        fontFamily: 'monospace',
        fontSize: '16px',
        color: '#479ADA',
    },
}));
const RefFlield = props => {
    const val = props.record[props.source];
    const styles = useStyle();
    return (
        <Typography variant="button" className={styles.code}>
            #{val}
        </Typography>
    );
};

export default RefFlield;
