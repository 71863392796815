import React, { useState, useEffect, useCallback, FC } from 'react';
import { useVersion, useDataProvider, useTranslate } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NbSubscriptions from './NbSubscriptions';
import LivreurMap from './LivreursMap';

interface State {
    nbTodaySubscriptions?: number;
    nbTodaySubscriptions2?: number;
    nbThisWeekSubscriptions?: number;
    nbThisWeekSubscriptions2?: number;
    nbThisMonthSubscriptions?: number;
    nbThisMonthSubscriptions2?: number;
}

const useStyles = makeStyles({
    card: {
        maxHeight: 1000,
        marginTop: 10,
        boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.15)',
        borderRadius: 5,
        height: 200,
    },
});

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    sectionTitle: { fontSize: 14 },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const classes = useStyles();

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const d = new Date();
    var day = d.getDay();
    d.setHours(0, 0, 0, 0);
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    const lastMonday = new Date(d.setDate(diff));

    const fetchUsers = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };

        query.filter = { date_gte: today.toISOString() };
        const { total: nbTodaySubscriptions } = await dataProvider.getList(
            'users',
            query
        );

        query.filter = { date_gte: firstDayOfMonth.toISOString() };
        const { total: nbThisMonthSubscriptions } = await dataProvider.getList(
            'users',
            query
        );

        query.filter = { date_gte: lastMonday.toISOString() };
        const { total: nbThisWeekSubscriptions } = await dataProvider.getList(
            'users',
            query
        );

        setState(state => ({
            ...state,
            nbTodaySubscriptions,
            nbThisWeekSubscriptions,
            nbThisMonthSubscriptions,
        }));
    }, [dataProvider, today, firstDayOfMonth, lastMonday]);

    const fetchLivreur = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };

        query.filter = { date_gte: today.toISOString() };
        const { total: nbTodaySubscriptions2 } = await dataProvider.getList(
            'livreurs',
            query
        );

        query.filter = { date_gte: firstDayOfMonth.toISOString() };
        const { total: nbThisMonthSubscriptions2 } = await dataProvider.getList(
            'livreurs',
            query
        );

        query.filter = { date_gte: lastMonday.toISOString() };
        const { total: nbThisWeekSubscriptions2 } = await dataProvider.getList(
            'livreurs',
            query
        );

        setState(state => ({
            ...state,
            nbTodaySubscriptions2,
            nbThisWeekSubscriptions2,
            nbThisMonthSubscriptions2,
        }));
    }, [dataProvider, today, firstDayOfMonth, lastMonday]);

    useEffect(() => {
        fetchUsers();
        fetchLivreur();
    }, [version]);

    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <div style={styles.flex}>
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.today"
                            value={state.nbTodaySubscriptions}
                            to="/users"
                        />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.today2"
                            value={state.nbTodaySubscriptions2}
                            to="/livreurs"
                        />
                        <Spacer />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.week"
                            to="/users"
                            value={state.nbThisWeekSubscriptions}
                        />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.week2"
                            to="/livreurs"
                            value={state.nbThisWeekSubscriptions2}
                        />
                        <Spacer />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.month"
                            to="/users"
                            value={state.nbThisMonthSubscriptions}
                        />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.month2"
                            to="/livreurs"
                            value={state.nbThisMonthSubscriptions2}
                        />
                    </div>
                </CardContent>
            </Card>
            <VerticalSpacer />
            <LivreurMap />
        </>
    );
};

export default Dashboard;
