import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate } from 'react-admin';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { Record } from 'ra-core';
interface Props {
    record?: Record;
    basePath?: string;
    label?: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        new: {
            color: 'white',
            backgroundColor: '#479ADA',
        },
        conceled: {
            color: 'white',
            backgroundColor: 'gray',
        },
        proposal_sent: {
            color: 'black',
            backgroundColor: 'yellow',
        },
        accepted: {
            color: 'black',
            backgroundColor: '#009688',
        },
        started: {
            color: 'white',
            backgroundColor: 'blue',
        },
        pickedup: {
            color: 'white',
            backgroundColor: 'orange',
        },
        shipped: {
            color: 'white',
            backgroundColor: 'green',
        },
        reception_confirmed: {
            color: 'white',
            backgroundColor: '#1d32f4',
        },
    })
);
const OrderStatusField = props => {
    const translate = useTranslate();
    const record = props.record;
    const classes = useStyles({});
    if (record) {
        return (
            <Chip
                size="small"
                label={translate(
                    `resources.orders.order_status.${record.status}`
                )}
                className={classes[record.status]}
            />
        );
    } else return null;
};
export default OrderStatusField;
