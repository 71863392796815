import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    ReferenceField,
    Filter,
    List,
    SearchInput,
    AutocompleteInput,
    ReferenceInput,
} from 'react-admin';

import RefField from './fields/RefField';
import OrderStatusField from './fields/OrderStatusField';
import PriceField from './fields/PriceField';
import FullAddressField from './fields/FullAddressField';
import OrderStatusInput from './inputs/OrderStatusInput';

const EntitiesFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput source="created_by" reference="users">
            <AutocompleteInput optionText="name.full" alwaysOn />
        </ReferenceInput>
        <OrderStatusInput source="status" />
    </Filter>
);
const EntityList = (props: any) => {
    return (
        <List
            {...props}
            filters={<EntitiesFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="show">
                <RefField source="ref" label="resources.orders.fields.ref" />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.orders.fields.created_at"
                />
                <PriceField
                    label="resources.orders.fields.price"
                    source="price"
                />
                <ReferenceField
                    label="resources.orders.fields.created_by"
                    source="created_by"
                    reference="users"
                >
                    <TextField source="name.full" />
                </ReferenceField>
                <FullAddressField
                    source="pickup"
                    label="resources.orders.fields.pickup.address"
                />
                <FullAddressField
                    source="shipping"
                    label="resources.orders.fields.shipping.address"
                />
                <OrderStatusField label="resources.orders.fields.status" />
            </Datagrid>
        </List>
    );
};

export default EntityList;
