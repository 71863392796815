import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    BooleanField,
    Filter,
    List,
    SearchInput,
    BooleanInput,
} from 'react-admin';
import ThumbnailField from './fields/ThumbnailField';
const PostFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <BooleanInput
            source="is_published"
            label="resources.posts.fields.is_published"
        />
    </Filter>
);
const PostList = (props: any) => {
    return (
        <List
            {...props}
            filters={<PostFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <ThumbnailField
                    source="image"
                    label="resources.posts.fields.image"
                />
                <TextField
                    source="title"
                    label="resources.posts.fields.title"
                />
                <BooleanField
                    source="is_published"
                    label="resources.posts.fields.is_published"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.posts.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default PostList;
