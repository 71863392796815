import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Pc, FieldProps } from '../types';
import Aside from './Aside';
import { NumberInput } from 'react-admin';

const CityEdit = (props: any) => {
    return (
        <Edit
            title={<PcTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <CityForm />
        </Edit>
    );
};

const PcTitle: FC<FieldProps<Pc>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;
const CityForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(30)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        type="name"
                                        source="name"
                                        resource="cities"
                                        label="resources.cities.fields.name"
                                        validate={validateName}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <NumberInput
                                        source="center.coordinates.0"
                                        label="resources.cities.fields.lon"
                                    />
                                </Box>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <NumberInput
                                        source="center.coordinates.1"
                                        label="resources.cities.fields.lat"
                                    />
                                </Box>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <NumberInput
                                        source="radius"
                                        label="resources.cities.fields.radius"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="cities"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default CityEdit;
