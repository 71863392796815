import React from 'react';

import { useTranslate } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        now: {
            color: 'white',
            backgroundColor: 'orange',
        },
        future: {
            color: 'white',
            backgroundColor: 'blue',
        },
    })
);
const UrgentField = props => {
    const classes = useStyles({});
    const translate = useTranslate();
    const when = props?.record?.pickup?.when;
    return (
        <Chip
            size="small"
            label={translate(`resources.orders.when.${when}`)}
            className={classes[when]}
        />
    );
};

export default UrgentField;
