import * as React from 'react';
import { FC } from 'react';
import { DateField, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, Box, Button } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core/styles';
import RoomIcon from '@material-ui/icons/Room';
import { Record } from 'ra-core';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

interface AsideProps {
    record?: Record;
    basePath?: string;
}

const Aside: FC<AsideProps> = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

interface EventListProps {
    record?: Record;
    basePath?: string;
}
const EventList: FC<EventListProps> = ({ record, basePath }) => {
    const translate = useTranslate();

    const gmapsUrl =
        record && record.location && record.location.coordinates
            ? `http://maps.google.com/maps?z=12&t=m&q=loc:${record.location.coordinates[1]}+${record.location.coordinates[0]}`
            : 'http://maps.google.com/maps';
    return (
        <>
            <Box m="0 0 1em 1em">
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.admins.fieldGroups.history')}
                        </Typography>
                        <Box display="flex" flexWrap="wrap">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.users.fields.created_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="created_at"
                                            locales="fr-FR"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.admins.fields.updated_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="updated_at"
                                            locales="fr-FR"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box flex="0 0 100%">
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <RoomIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.users.fields.last_position_at'
                                            )}
                                        </Typography>
                                        <Box
                                            display="flex"
                                            mt="1em"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <DateField
                                                record={record}
                                                source="last_position_at"
                                                showTime
                                                options={{ hourCycle: 'h23' }}
                                                locales="fr-FR"
                                            />
                                            <Button
                                                target="_blank"
                                                color="default"
                                                size="small"
                                                startIcon={<RoomIcon />}
                                                href={gmapsUrl}
                                            >
                                                Voir sur Carte
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Aside;
