import * as React from 'react';
import { FC } from 'react';
import {
    Show,
    Labeled,
    TextField,
    DateField,
    ArrayField,
    ReferenceField,
    NumberField,
    Datagrid,
    TopToolbar
} from 'react-admin';
import { RaBox, BoxedShowLayout } from 'ra-compact-ui';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Order, FieldProps } from '../types';
import { Typography } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import { Dialog } from '@material-ui/core';

import RefField from './fields/RefField';
import OrderStatusField from './fields/OrderStatusField';

import PriceField from './fields/PriceField';
import FullAddressField from './fields/FullAddressField';
import UrgentField from './fields/UrgentField';
import Thumbnailfield from './fields/ThumbnailField';
import FullNameField from '../users/FullNameField';
// import Assign from './assign';
import AssignToLivreur from './popup'

const Title: FC<FieldProps<Order>> = ({ record }) =>
    record ? <Typography>Commande</Typography> : null;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        detailsBox: {
            paddingLeft: '50px',
            marginLeft: '50px',
        },
        element: {
            marginBottom: '30px',
        },
        section: {
            boxShadow: '-5px 0px 5px #ccc',
            padding: '20px',
            marginTop: '20px',
        },
        headerSection: {
            fontWeight: 700,
            backgroundColor: '#eee',
            padding: '20px',
        },
        sectionCol: {
            flexGrow: 1,
            width: '33%',
            marginBottom: '10px',
        },
    })
);



const EntityShow = (props: any) => {

    const classes = useStyles();
    
    const PostShowActions = (props) => (
        <TopToolbar>
            <AssignToLivreur {...props} />
        </TopToolbar>
    )

    return (
        <Show {...props} component="div" tltle={<Title />} 
        actions={
        <PostShowActions {...props} />
        }>
            <BoxedShowLayout>
                <RaBox display="flex">
                    <RaBox
                        display="inline-flex"
                        flexDirection="column"
                        flexGrow={1}
                    >
                        <UrgentField
                            label="resources.orders.fields.pickup.when"
                            className={classes.element}
                        />
                        <RefField
                            addLabel={true}
                            source="ref"
                            label="resources.orders.fields.ref"
                            className={classes.element}
                        />
                        <OrderStatusField
                            addLabel={true}
                            label="resources.orders.fields.status"
                            className={classes.element}
                        />
                        <DateField
                            source="created_at"
                            type="date"
                            label="resources.orders.fields.created_at"
                            className={classes.element}
                        />
                        <ReferenceField
                            label="resources.orders.fields.created_by"
                            source="created_by"
                            reference="users"
                            className={classes.element}
                        >
                            <FullNameField />
                        </ReferenceField>
                    </RaBox>
                    <RaBox
                        display="flex"
                        flexWrap="wrap"
                        className={classes.detailsBox}
                    >
                        <RaBox
                            flex="0 0 100%"
                            display="flex"
                            justifyContent="space-around"
                            flexGrow={4}
                            className={classes.headerSection}
                        >
                            <Labeled label="resources.orders.fields.price_ttc">
                                <PriceField source="price_ttc" />
                            </Labeled>
                            <NumberField
                                source="delivery.distance"
                                label="resources.orders.fields.delivery.distance"
                                options={{ maximumFractionDigits: 0 }}
                            />
                            <Labeled label="resources.users.fields.vehicule_type">
                                <ReferenceField
                                    label="resources.orders.fields.vehicule_type"
                                    source="vehiculeType"
                                    reference="vehiculeTypes"
                                    className={classes.element}
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                            </Labeled>
                            <ReferenceField
                                label="resources.orders.fields.delivery.by"
                                source="delivery.by"
                                reference="livreurs"
                            >
                                <FullNameField />
                            </ReferenceField>
                        </RaBox>
                        <RaBox
                            flex="0 0 100%"
                            display="flex"
                            justifyContent="space-around"
                            flexWrap="wrap"
                            className={classes.section}
                        >
                            <RaBox flex="0 0 100%">
                                <Typography variant="h6">Pickup</Typography>
                            </RaBox>
                            <TextField
                                source="pickup.contact.name"
                                label="resources.orders.fields.pickup.contact.name"
                                className={classes.sectionCol}
                            />
                            <TextField
                                source="pickup.contact.company"
                                label="resources.orders.fields.pickup.contact.company"
                                className={classes.sectionCol}
                            />
                            <TextField
                                source="pickup.contact.phone"
                                label="resources.orders.fields.pickup.contact.phone"
                                className={classes.sectionCol}
                            />
                            <FullAddressField
                                addLabel={true}
                                source="pickup"
                                label="resources.orders.fields.pickup.address"
                                className={classes.sectionCol}
                            />

                            <TextField
                                source="pickup.city.name"
                                label="resources.orders.fields.pickup.city"
                                className={classes.sectionCol}
                            />
                            <DateField
                                source="pickup.date"
                                showTime={true}
                                label="resources.orders.fields.pickup.date"
                                className={classes.sectionCol}
                            />
                        </RaBox>
                        <RaBox
                            flex="0 0 100%"
                            display="flex"
                            justifyContent="space-around"
                            flexWrap="wrap"
                            className={classes.section}
                        >
                            <RaBox flex="0 0 100%">
                                <Typography variant="h6">Livraison</Typography>
                            </RaBox>
                            <TextField
                                source="shipping.contact.name"
                                label="resources.orders.fields.shipping.contact.name"
                                className={classes.sectionCol}
                            />
                            <TextField
                                source="shipping.contact.company"
                                label="resources.orders.fields.shipping.contact.company"
                                className={classes.sectionCol}
                            />
                            <TextField
                                source="shipping.contact.phone"
                                label="resources.orders.fields.shipping.contact.phone"
                                className={classes.sectionCol}
                            />
                            <FullAddressField
                                addLabel={true}
                                source="shipping"
                                label="resources.orders.fields.shipping.address"
                                className={classes.sectionCol}
                            />

                            <TextField
                                source="shipping.city.name"
                                label="resources.orders.fields.shipping.city"
                                className={classes.sectionCol}
                            />
                            <DateField
                                source="shipping.date"
                                showTime={true}
                                label="resources.orders.fields.shipping.date"
                                className={classes.sectionCol}
                            />
                        </RaBox>
                        <RaBox flex="0 100% 100%" display="flex" mt="20px">
                            <ArrayField
                                source="prducts"
                                label="resources.orders.fields.prducts.label"
                            >
                                <Datagrid>
                                    <Thumbnailfield source="image" />
                                    <ReferenceField
                                        label="resources.orders.fields.prducts.prduct"
                                        source="prduct.id"
                                        reference="prducts"
                                    >
                                        <TextField source="title" />
                                    </ReferenceField>
                                    <NumberField
                                        source="quantity"
                                        label="resources.orders.fields.prducts.quantity"
                                    />
                                </Datagrid>
                            </ArrayField>
                        </RaBox>
                    </RaBox>
                </RaBox>

            </BoxedShowLayout>
        </Show>
    );
};

export default EntityShow;
