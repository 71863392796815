import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
} from 'react-admin';
const PcFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);
const CityList = (props: any) => {
    return (
        <List
            {...props}
            filters={<PcFilter />}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="name" label="resources.cities.fields.name" />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.cities.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default CityList;
