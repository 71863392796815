import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import Assign from './assign';


const AssignToLivreur = (props) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Assigner au livreur
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Assigner cette commande au livreur</DialogTitle>
        <DialogContent>
          <Assign {...props} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignToLivreur;
