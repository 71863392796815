import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const toChoices = (items, translate) =>
    items.map(item => ({
        id: item,
        name: translate(`resources.orders.order_status.${item}`),
    }));
const OrderStatusInput = props => {
    const translate = useTranslate();
    const status = [
        'new',
        'proposal_sent',
        'conceled',
        'accepted',
        'started',
        'pickedup',
        'shipped',
        'reception_confirmed',
    ];
    return (
        <SelectInput
            choices={toChoices(status, translate)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default OrderStatusInput;
