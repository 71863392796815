import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Avatar, Box, CardHeader, Typography } from '@material-ui/core';
import { DateField } from 'react-admin';
import { TextField } from 'react-admin';

const refereshInterval = process.env.MAP_REFRESH_INTERVAL || 30000;
const position = [29.5347111527512, -9.321283118638608];
var greenIcon = new L.Icon({
    iconUrl: '/map/marker-icon-2x-green.png',
    shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});
var redIcon = new L.Icon({
    iconUrl: '/map/marker-icon-2x-red.png',
    shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const PopupContent = function ({ livreur }) {
    const translate = useTranslate();
    return (
        livreur && (
            <Box>
                <CardHeader
                    style={{ padding: 0 }}
                    avatar={<Avatar src={livreur.avatar_url} />}
                    title={livreur.name.full}
                />
                <Box display="flex" py={1}>
                    <Box flex={2} mb={1} mr={1}>
                        <Typography variant="p" display="block">
                            {translate('resources.users.fields.last_position_at')}
                        </Typography>
                        <DateField
                            record={livreur}
                            source="last_position_at"
                            showTime={true}
                        />
                    </Box>
                </Box>
                <Box display="flex" py={2}>
                    <Box flex={2} mb={2} mr={1}>
                        <Typography variant="p" display="block">
                            {translate('resources.users.fields.phone')}
                        </Typography>
                        <TextField
                            record={livreur}
                            source="phone"
                            showTime={true}
                        />
                    </Box>
                    <Box flex={2} mb={2} mr={1}>
                        <Typography variant="p" display="block">
                            {translate('resources.users.fields.email')}
                        </Typography>
                        <TextField
                            record={livreur}
                            source="email"
                            showTime={true}
                        />
                    </Box>
                </Box>
            </Box>
        )
    );
};

export default function LivreurMap() {
    const [state, setState] = useState({ livreurs: [] });

    const dataProvider = useDataProvider();

    const fetchLivreurs = useCallback(async () => {
        let query = {
            filter: { role: 'livreur' },
            sort: { field: 'date', order: 'DESC' },
            expand: 'vehiculeType',
            pagination: { page: 1, perPage: 1000 },
        };
        const result = await dataProvider.getList('livreurs', query);
        setState(state => ({
            ...state,
            livreurs: result.data,
        }));
    }, [dataProvider]);

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
          fetchLivreurs();
        };
      
        // Call the fetchData function immediately
        fetchData();
      
        // Set up the interval to fetch data periodically
        const intervalId = setInterval(fetchData, refereshInterval);
      
        // Return a cleanup function
        return () => {
          // Clear the interval when the component is unmounted or before the effect runs again
          clearInterval(intervalId);
        };
      }, [fetchLivreurs]);
      

    return (
        <MapContainer center={position} zoom={6} style={{ height: '100%' }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {state &&
                state.livreurs &&
                state.livreurs.map(livreur => (
                    <Marker
                        key={livreur.id}
                        icon={livreur.is_online ? greenIcon : redIcon}
                        position={livreur.location.coordinates.reverse()}
                    >
                        <Popup>
                            <PopupContent livreur={livreur} />
                        </Popup>
                    </Marker>
                ))}
        </MapContainer>
    );
}
