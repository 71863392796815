import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, MenuItemLink, DashboardMenuItem } from 'react-admin';

import users from '../users';
import livreurs from '../livreurs';
import prducts from '../prducts';
import cities from '../cities';
import categories from '../categories';
import orders from '../orders';
import slides from '../slides';
import vehiculeTypes from '../vehiculeTypes';
import admins from '../admins';
// import DashboardMenuItem from './DashboardMenuItem';

import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuUsers' | 'menuDelivery';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={`/orders`}
                primaryText={translate(`resources.orders.name`, {
                    smart_count: 2,
                })}
                leftIcon={<orders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/prducts`}
                primaryText={translate(`resources.prducts.name`, {
                    smart_count: 2,
                })}
                leftIcon={<prducts.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/categories`}
                primaryText={translate(`resources.categories.name`, {
                    smart_count: 2,
                })}
                leftIcon={<categories.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/cities`}
                primaryText={translate(`resources.cities.name`, {
                    smart_count: 2,
                })}
                leftIcon={<cities.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/vehiculeTypes`}
                primaryText={translate(`resources.VehiculeTypes.name`, {
                    smart_count: 2,
                })}
                leftIcon={<vehiculeTypes.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/slides`}
                primaryText={translate(`resources.slides.name`, {
                    smart_count: 2,
                })}
                leftIcon={<slides.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/users`}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/livreurs`}
                primaryText={translate(`resources.livreurs.name`, {
                    smart_count: 2,
                })}
                leftIcon={<livreurs.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
                <MenuItemLink
                to={`/admins`}
                primaryText={translate(`resources.admins.name`, {
                    smart_count: 2,
                })} 
                leftIcon={<admins.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
