import * as React from 'react';

const HyperLink = props => {
    const { type = 'url', href = '', label = '', target = 'blank' } = props;

    let hrefValue = '';
    switch (type) {
        case 'url':
            hrefValue = href;
            break;
        case 'email':
            hrefValue = `mailto:${href}`;
            break;
        case 'phone':
            hrefValue = `tel:${href}`;
            break;

        default:
            hrefValue = href;
            break;
    }
    return (
        <p>
            <a href={hrefValue} target={target}>
                {label}{' '}
            </a>
        </p>
    );
};

export default HyperLink;
