import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Prduct, FieldProps } from '../types';
import Aside from './Aside';

const PrductEdit = (props: any) => {
    return (
        <Edit
            title={<PrductTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <PrductForm />
        </Edit>
    );
};

const PrductTitle: FC<FieldProps<Prduct>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const PrductForm = (props: any) => {
    const validatetitle = [required(), minLength(1), maxLength(30)];
    const validatedescription = [maxLength(1024)];
    const validatePublished = [];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <BooleanInput
                                        source="is_published"
                                        label="resources.prducts.fields.is_published"
                                        validate={validatePublished}
                                    />
                                    <TextInput
                                        source="title"
                                        label="resources.prducts.fields.title"
                                        validate={validatetitle}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="description"
                                        label="resources.prducts.fields.description"
                                        validate={validatedescription}
                                        fullWidth
                                        multiline
                                    />
                                    <ReferenceInput
                                        label="resources.prducts.fields.category"
                                        source="category"
                                        reference="categories"
                                    >
                                        <SelectInput optionText="title" />
                                    </ReferenceInput>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="prducts"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default PrductEdit;
