import ShoppingCart from '@material-ui/icons/ShoppingCart';

import list from './List';
import show from './Show';

export default {
    list,
    show,
    icon: ShoppingCart,
};
