import PinDropIcon from '@material-ui/icons/PinDrop';

import List from './CityList';
import Edit from './CityEdit';
import Create from './CityCreate';

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: PinDropIcon,
};
