import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    Show,
    TextInput,
    DateInput,
    TabbedShowLayout,
    Tab,
    PasswordInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    email,
    BooleanInput,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { validatePasswords } from './UserCreate';
import { Customer, FieldProps } from '../types';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import HyperLink from '../inputs/HyperLink';

const API_URL = process.env.REACT_APP_REST_API_URL;

const EditUser = (props: any) => {
    return (
        <Edit
            title={<UserTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <UserForm />
        </Edit>
    );
};
const Title: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <Typography>Livreur</Typography> : null;

const UserTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="name.first"
                                        resource="users"
                                        label="resources.users.fields.name"
                                        validate={requiredValidate}
                                        fullWidth
                                    />

                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="users"
                                        label="resources.users.fields.email"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="phone"
                                        source="phone"
                                        label="resources.users.fields.phone"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    <DateInput
                                        type="birthdate"
                                        source="birthdate"
                                        label="resources.users.fields.birthdate"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    <DateInput
                                        type="birthdate"
                                        source="birthdate"
                                        label="resources.users.fields.birthdate"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    {/* <VehiculeTypeInput
                                        source="vehicule_type"
                                        label="resources.users.fields.vehicule_type"
                                    /> */}
                                    <ReferenceInput
                                        label="resources.users.fields.vehicule_type"
                                        source="vehiculeType"
                                        reference="vehiculeTypes"
                                    >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                    <BooleanInput
                                        source="account_enabled"
                                        label="resources.users.fields.account_enabled"
                                        fullWidth
                                    />

                                    <Box mt="1em" />

                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.address'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="address1"
                                        resource="users"
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="zipcode"
                                                resource="users"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={2}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="city"
                                                resource="users"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box> */}

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.change_password'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.pricing'
                                        )}
                                    </Typography>

                                    <SelectInput
                                        source="priceCategory"
                                        choices={[
                                            { id: 'A', name: 'tranche A' },
                                            { id: 'B', name: 'tranche B' },
                                            { id: 'C', name: 'tranche C' },
                                            { id: 'D', name: 'tranche D' },
                                        ]}
                                    />

                                </Box> */}
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="livreurs"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const UserDocuments = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.identity'
                                        )}
                                    </Typography> */}
                                </Box>
                            </Box>
                            <Box mt="1em" />
                            <div>
                                <HyperLink
                                    record={formProps.record}
                                    label={translate(
                                        'resources.users.fields.image_cin_recto'
                                    )}
                                    href={`${API_URL}/files/${formProps.record.image_cin_recto}/download`}
                                />
                            </div>
                            <Box mt="1em" />
                            <div>
                                <HyperLink
                                    record={formProps.record}
                                    label={translate(
                                        'resources.users.fields.image_cin_verso'
                                    )}
                                    href={`${API_URL}/files/${formProps.record.image_cin_verso}/download`}
                                />
                            </div>
                            <Box mt="1em" />
                            <div>
                                <HyperLink
                                    record={formProps.record}
                                    label={translate(
                                        'resources.users.fields.image_car_licence_recto'
                                    )}
                                    href={`${API_URL}/files/${formProps.record.image_car_licence_recto}/download`}
                                />
                            </div>
                            <Box mt="1em" />
                            <div>
                                <HyperLink
                                    record={formProps.record}
                                    label={translate(
                                        'resources.users.fields.image_car_licence_verso'
                                    )}
                                    href={`${API_URL}/files/${formProps.record.image_car_licence_verso}/download`}
                                />
                            </div>
                            {/* <Box mt="1em" />
                            <div>
                                <HyperLink
                                    record={formProps.record}
                                    label={translate(
                                        'resources.users.fields.image_car_outside'
                                    )}
                                    href={`${API_URL}/files/${formProps.record.image_car_outside}/download`}
                                />
                            </div> */}
                        </CardContent>
                    </form>
                </Card>
            )}
        />
    );
};

const UserEdit = (props: any) => {
    return (
        <Show {...props} component="div" title={<Title />}>
            <TabbedShowLayout>
                <Tab label="Detail ">
                    <EditUser {...props} />
                </Tab>
                <Tab label="Documents">
                    <UserDocuments {...props} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const requiredValidate = [required()];

export default UserEdit;
