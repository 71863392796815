import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Pc, FieldProps } from '../types';
import Aside from './Aside';
import MyImageInput from '../inputs/MyImageInput';
import { ArrayInput } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';
import { NumberInput } from 'react-admin';

const VehiculeTypeEdit = (props: any) => {
    return (
        <Edit
            title={<PcTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <VehiculeTypeForm />
        </Edit>
    );
};

const PcTitle: FC<FieldProps<Pc>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;
const VehiculeTypeForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(30)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        type="name"
                                        source="name"
                                        resource="VehiculeTypes"
                                        label="resources.VehiculeTypes.fields.name"
                                        validate={validateName}
                                        fullWidth
                                    />
                                    <MyImageInput
                                        source="image"
                                        label="resources.VehiculeTypes.fields.image"
                                        validate={[required()]}
                                    />
                                    <ArrayInput
                                        source="prices"
                                        label="resources.VehiculeTypes.fields.prices"
                                    >
                                        <SimpleFormIterator>
                                            <NumberInput
                                                source="from"
                                                label="resources.VehiculeTypes.fields.price_from"
                                                fullWidth
                                            />
                                            <NumberInput
                                                source="value"
                                                label="resources.VehiculeTypes.fields.price_value"
                                                fullWidth
                                            />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="VehiculeTypes"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default VehiculeTypeEdit;
