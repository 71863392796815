import * as React from 'react';
import { useState } from 'react';
import {
    FormWithRedirect,
    AutocompleteInput,
} from 'react-admin';
import { Card, CardContent, } from '@material-ui/core';
import { RaBox } from 'ra-compact-ui';
import { ReferenceInput } from 'react-admin';
import SaveButtonWithEndpoint from './assignsave';

const EditOrder = (props: any) => {
    return (
        <OrderForm />
    );
};

// const disaleAssignOrder = (formProps: any) => {
//     if (formProps.status !== 'consle') {
//       return  formProps.record.delivery.by
//     }
// };

const OrderForm = (props: any) => {
    const [division, setDivision] = useState('');

    const handleDivisionChange = (event: any) => {
        if (event) {
            setDivision(event);
        }
    };

    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <RaBox>
                                <ReferenceInput
                                    onChange={handleDivisionChange}
                                    label="resources.orders.assign"
                                    source="delivery.by"
                                    reference="livreurs"
                                    disabled={formProps.record.delivery.by}

                                >
                                    <AutocompleteInput
                                        optionText="name.full"
                                        fullWidth

                                    />
                                </ReferenceInput>
                            </RaBox>
                        </CardContent>
                        {!formProps.record.delivery.by
                            && <SaveButtonWithEndpoint
                                {...formProps}
                                division={division}
                                deliveryby={formProps.record.delivery.by}
                                disabled={formProps.record.delivery.by} />}

                    </form>
                </Card>
            )}
        />
    );
};




export default EditOrder;


