import { defaultTheme } from 'react-admin';
import defaultMuiTheme from '@material-ui/core/styles/defaultTheme';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { PaletteType } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';
import merge from 'lodash/merge';
export const TOOLBAR_MIN_HEIGHT = 15;

const paletteOptions = {
    primary: {
        main: '#2181C9',
    },
    secondary: blue,
};

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        primary: {
            main: '#570318',
            light: '#570318',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#54565E',
            light: '#570318',
            dark: '#001064',
            contrastText: '#54565E',
        },
        background: {
            default: '#fcfcfe',
        },
        text: {
            primary: '#54565E',
            secondary: '#570318',
        },

        textShadow:
            '#242C36 0px 2px 10px, #242C36 2px 0px 10px, #242C36 0px -2px 10px, #242C36 -2px 0px 10px',
        type: 'light',
    })
);

const typography = createTypography(palette, {
    fontFamily: [
        'Montserrat',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    //first section name
    h1: {
        color: palette.secondary.contrastText,
        marginTop: defaultMuiTheme.spacing(2),
        paddingLeft: defaultMuiTheme.spacing(2),
        paddingTop: defaultMuiTheme.spacing(2),
        fontFamily: 'Montserrat',
        letterSpacing: defaultMuiTheme.spacing(0.2),
        // textShadow: palette.textShadow,
    },
    //section titles with slide/alphain effect
    h2: {
        color: palette.secondary.main,
        paddingBottom: defaultMuiTheme.spacing(2),
        paddingTop: defaultMuiTheme.spacing(2),
        fontFamily: 'Montserrat',
        letterSpacing: defaultMuiTheme.spacing(0.2),
    },
    //first section subtitle
    h3: {
        fontFamily: 'Montserrat',
        color: palette.secondary.contrastText,
        padding: defaultMuiTheme.spacing(2),
        paddingTop: defaultMuiTheme.spacing(0),
        // textShadow: palette.textShadow,
    },
    //expansion sums/ct card titles/contact subtitle
    h4: {
        padding: defaultMuiTheme.spacing(2),
    },
    //slidingInfo title
    h5: {
        color: palette.secondary.main,
    },
    h6: {
        color: palette.secondary.contrastText,
    },
    body1: {
        fontWeight: 'inherit',
    },
    body2: {
        fontWeight: 'inherit',
    },
    //skills items/pdf link
    subtitle1: {
        fontWeight: 'inherit',
        fontStyle: '',
    },
});

const globaShape = {
    borderRadius: 12,
};

const themeBase = {
    palette,
    typography: {
        ...typography,
        h2: {
            ...typography.h2,
            fontWeight: 500,
        },
    },
    shape: {
        borderRadius: 12,
    },
    overrides: {
        RaSidebar: {
            drawerPaper: {
                backgroundColor: '#ffffff',
                color: '#fff',
                marginTop: '0em',
                height: 'calc(100% - 1.5em)',
                position: 'relative',
                whiteSpace: 'nowrap',
                transition: defaultMuiTheme.transitions.create('width', {
                    easing: defaultMuiTheme.transitions.easing.sharp,
                    duration:
                        defaultMuiTheme.transitions.duration.enteringScreen,
                }),
            },
        },
        RaLayout: {
            root: {
                display: 'flex',
                flexDirection: 'column',
                zIndex: 1,
                minHeight: '100vh',
                position: 'relative',
                minWidth: 'fit-content',
                width: '100%',
            },
            appFrame: {
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                [defaultMuiTheme.breakpoints.up('xs')]: {
                    marginTop: defaultMuiTheme.spacing(6),
                },
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    marginTop: defaultMuiTheme.spacing(9),
                },
            },
            contentWithSidebar: {
                display: 'flex',
                flexGrow: 1,
            },
            content: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexBasis: 0,
                padding: defaultMuiTheme.spacing(3),
                paddingTop: defaultMuiTheme.spacing(6),
                paddingLeft: 0,
                [defaultMuiTheme.breakpoints.up('xs')]: {
                    paddingLeft: defaultMuiTheme.spacing(3),
                },
                [defaultMuiTheme.breakpoints.down('sm')]: {
                    padding: 0,
                },
            },
        },
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
                color: palette.text.secondary,
                '&:hover': {
                    border: `2px solid ${palette.primary.light}`,
                },
            },
            active: {
                color: '#ffffff',
                backgroundColor: palette.primary.light,
                borderLeft: `5px solid ${palette.text.secondary}`,
                borderRight: `5px solid ${palette.text.secondary}`,
                '&:hover': {
                    backgroundColor: palette.primary.light,
                    borderLeft: `5px solid ${palette.text.secondary}`,
                    borderRight: `5px solid ${palette.text.secondary}`,
                },
                '& svg': {
                    color: '#ffffff',
                },
            },
        },
        // MuiContainer: {
        //     root: {
        //         backgroundColor: palette.primary.main,
        //         overflowX: 'hidden',
        //     },
        // },
        MuiSvgIcon: {
            root: {
                color: palette.text.secondary,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: palette.secondary.main,
                marginBottom: defaultMuiTheme.spacing(4),
            },
            middle: {
                backgroundColor: palette.primary.light,
                marginTop: defaultMuiTheme.spacing(2),
                marginBottom: defaultMuiTheme.spacing(2),
            },
        },
        MuiButton: {
            root: {
                borderRadius: globaShape.borderRadius,
                border: `1px solid ${palette.primary.light}`,
                //icon of button
                '& svg': {
                    color: '#1F1F1F',
                },
            },
            outlined: {
                border: `1px solid ${palette.primary.light}`,
                color: palette.secondary.main,
                '&:hover': {
                    border: `1px solid ${palette.secondary.contrastText}`,
                    background: palette.primary.light,
                },
            },
        },
        MuiAppBar: {
            // positionFixed: {
            //     zIndex: 1100,
            //     marginTop: 12,
            //     height: 'none',
            // },
            colorSecondary: {
                color: palette.primary.dark,
                backgroundColor: '#ffffff',
                borderColor: '#ffffff',
            },
        },
        MuiToolbar: {
            gutters: {
                minHeight: TOOLBAR_MIN_HEIGHT,
                '@media (min-width: 600px)': {
                    minHeight: TOOLBAR_MIN_HEIGHT,
                },
            },
            regular: {
                minHeight: TOOLBAR_MIN_HEIGHT,
                '@media (min-width: 600px)': {
                    minHeight: TOOLBAR_MIN_HEIGHT,
                },
            },
            root: {
                minHeight: TOOLBAR_MIN_HEIGHT,
                '@media (min-width: 600px)': {
                    minHeight: TOOLBAR_MIN_HEIGHT,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: '10px',
                color: palette.primary.light,
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                },
            },
            edgeEnd: {
                //target skills arrows
                '& .MuiSvgIcon-root': {
                    fontSize: defaultMuiTheme.spacing(5),
                },
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: alpha(palette.secondary.dark, 0.4),
            },
        },
        MuiPaper: {
            root: {
                // backgroundColor: 'transparent',
                border: `1px solid ${palette.primary.light}`,
                borderRadius: 0,
                color: palette.secondary.contrastText,
            },
        },
        MuiCardMedia: {
            root: {
                border: `1px solid ${palette.primary.light}`,
                borderRadius: globaShape.borderRadius,
            },
        },
        MuiCardActions: {
            root: {
                justifyContent: 'space-between',
            },
        },
        MuiExpansionPanelSummary: {
            content: {
                color: palette.secondary.contrastText,
            },
        },
        MuiExpansionPanelDetails: {
            root: {
                color: palette.secondary.contrastText,
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
        MuiFormLabel: {
            root: {
                color: palette.secondary.contrastText,
            },
        },
        MuiInputBase: {
            root: {
                color: palette.secondary.contrastText,
            },
        },
        //zone de text  Input
        MuiFilledInput: {
            root: {
                transition: defaultMuiTheme.transitions.create(
                    ['background-color', 'border'],
                    {
                        duration: defaultMuiTheme.transitions.duration.shorter,
                    }
                ),
                border: `0.5px solid ${palette.primary.light}`,
                borderRadius: globaShape.borderRadius,
                borderTopLeftRadius: globaShape.borderRadius,
                borderTopRightRadius: globaShape.borderRadius,
                backgroundColor: '#ffffff',
                '&$disabled': {
                    backgroundColor: '#ffffff',
                    border: `0.5px dashed ${palette.primary.light}`,
                    '&$disabled': {
                        color: 'rgba(0, 0, 0, 0.8)',
                    },
                },
                '&:hover': {
                    border: `2px solid ${palette.primary.light}`,
                    backgroundColor: alpha(
                        palette.text.primary,
                        palette.action.hoverOpacity
                    ),
                },
                '&.Mui-focused': {
                    backgroundColor: alpha(
                        palette.text.primary,
                        defaultMuiTheme.palette.action.hoverOpacity
                    ),
                },
                '&.MuiFilledInput-underline:before ': {
                    borderBottom: 'none !important',
                },
                '&.MuiFilledInput-underline:after': {
                    color: 'red',
                    marginRight: '2px !important',
                    marginLeft: '2px !important',
                    borderRadius: '4px !important',
                },
            },
        },
        //zone de text avec Input +Container div
        MuiTextField: {
            root: {
                // transition: defaultTheme.transitions.create(
                //     ['background-color', 'border'],
                //     {
                //         duration: defaultTheme.transitions.duration.short,
                //     }
                // ),
                // '&:hover': {
                //     border: `2px solid ${palette.primary.light}`,
                //     // backgroundColor: alpha(palette.text.primary, 5),
                // },
                '& label.Mui-focused': {
                    color: palette.primary.light,
                    fontWeight: 'bold',
                },
                '& .MuiOutlinedInput-root': {
                    color: palette.primary.light,
                    '& fieldset': {
                        borderColor: palette.primary.light,
                    },
                    '&:hover fieldset': {
                        borderColor: palette.primary.light,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: palette.primary.light,
                        borderWidth: '1px',
                    },
                },
            },
            outlined: {
                // border: `1px solid ${palette.primary.light}`,
                // color: palette.secondary.main,
                // '&:hover': {
                //     border: `1px solid ${palette.secondary.contrastText}`,
                //     background: palette.primary.light,
                // },
            },
        },
        MuiLink: {
            root: {
                color: '#C9C9C9',
            },
            underlineHover: {
                '&:hover': {
                    textDecoration: 'none',
                    color: '#2F2F2F',
                },
            },
        },
        MuiSwitch: {
            track: {
                border: `1px solid ${palette.primary.light}`,
                backgroundColor: palette.primary.contrastText,
            },
        },
    },
    props: {
        MuiFilledInput: {
            disableUnderline: true,
        },
        MuiTextField: {
            variant: 'outlined' as 'outlined',
        },
    },
};

export const lightTheme = responsiveFontSizes(createTheme(themeBase));

export const darkTheme = responsiveFontSizes(
    createTheme({
        ...themeBase,
        palette: {
            ...paletteOptions,
            type: 'dark',
        },
    })
);

export const getTheme = (type: PaletteType) =>
    type === 'light' ? lightTheme : lightTheme;
