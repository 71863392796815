import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Pass',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            subscriptions: {
                title: "Nombre d'inscriptions",
                titleLivreur: "Nombre d'inscriptions de Clients",
                titleClient: "Nombre d'inscriptions de transporteurs",
                today: "Aujourd'hui (clients)",
                today2: "Aujourd'hui (livreurs)",
                week: 'Cette Semaine (clients)',
                week2: 'Cette Semaine (livreurs)',
                month: 'Ce Mois (clients)',
                month2: 'Ce Mois (livreurs)',
            },
            products: {
                title: 'Nombre de Produits Rajoutés',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            selles: {
                title: 'Nombre de Produits Vendus',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            ca: {
                title: "Chiffre d'Affaire en DH",
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },

            month_history: "Chiffre d'affaire sur 30 jours",
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items: 'par %{customer_name}, un produit |||| par %{customer_name}, %{nb_items} produits',
            },
            welcome: {
                title: 'Bienvenue dans EZZAL',
                subtitle: 'Ceci est le back-office EZZAL..',
                aor_button: 'Site web de EZZAL',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            prducts: 'Produits',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom Complet',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                count_quizzes: 'Nombre de Quizzes passés',
                score: 'Score',
                vehicule_type: 'Type de Véhicule',
                last_position_at: 'Dernière Position',
                image_cin_recto: 'CIN recto',
                image_cin_verso: 'CIN verso',
                image_car_licence_recto: 'licence de Voiture recto',
                image_car_licence_verso: 'licence de Voiture verso',
                image_car_outside: "L'image de voiture à l'extérieur",
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                image_cin_recto: 'CIN recto',
                image_cin_verso: 'CIN verso',
                image_car_licence_recto: 'licence de Voiture recto',
                image_car_licence_verso: 'licence de Voiture verso',
                image_car_outside: "L'image de voiture à l'extérieur",
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        livreurs: {
            name: 'Livreur |||| Livreurs',
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                accountEnabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                image_cin_recto: 'CIN recto',
                image_cin_verso: 'CIN verso',
                image_car_licence_recto: 'licence de Voiture recto',
                image_car_licence_verso: 'licence de Voiture verso',
                image_car_outside: "L'image de voiture à l'extérieur",
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        banners: {
            name: 'Bannière |||| Bannières',
            fields: {
                name: 'Nom',
                image: 'Image',
                target: 'Cible',
                targetModel: 'Type',
                category: 'Catégorie',
                feature: 'Feature',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        posts: {
            name: 'Actvitié |||| Actvitiés',
            fields: {
                title: 'Titre',
                short_description: 'Description courte',
                content: 'Contenue',
                image: 'Image',
                file: 'Document',
                is_published: 'Publié',
                created_at: 'Date de Création',
            },
        },
        prducts: {
            name: 'Produit |||| Produits',
            fields: {
                title: 'Titre',
                description: 'Desciption',
                category: 'Catégorie',
                is_published: 'Publié',
                created_at: 'Date de Création',
            },
        },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                title: 'Titre',
                category: 'Catégorie',
                created_at: 'Date de Création',
            },
        },
        cities: {
            name: 'Ville |||| Villes',
            fields: {
                name: 'Nom',
                lon: 'Longitude',
                lat: 'Latitude',
                radius: 'Rayon(mêtre)',
                created_at: 'Date de Création',
            },
        },
        VehiculeTypes: {
            name: 'Type de Véhicule |||| Types de Véhicules',
            fields: {
                name: 'Nom',
                image: 'Image',
                prices: 'Tranches tatifiaires',
                price_from: 'A partir de',
                price_value: 'Tarif/km',
                created_at: 'Date de Création',
            },
        },
        orders: {
            assign : 'Assigner au livreur',
            name: 'Commande |||| Commandes',
            fields: {
                ref: 'Référence',
                created_by: 'Client',
                created_at: 'Date de Création',
                status: 'Status',
                price: 'Prix',
                price_ttc: 'Total TTC',
                pickup: {
                    contact: {
                        name: 'Nom',
                        company: 'Entreprise',
                        phone: 'N° Téléphone',
                    },
                    address: 'Adresse Pickup',
                    city: 'Ville Pickup',
                    date: 'Date Pickup',
                    when: 'Quand?',
                },
                shipping: {
                    contact: {
                        name: 'Nom',
                        company: 'Entreprise',
                        phone: 'N° Téléphone',
                    },
                    address: 'Adresse Livraison',
                    city: 'Ville Livraison',
                    date: 'Date Livraison',
                },
                delivery: {
                    distance: 'Distance en KM',
                    by: 'Livrée par',
                },
                prducts: {
                    label: 'Produits',
                    prduct: 'Produit',
                    quantity: 'Quantité',
                },
            },
            order_status: {
                new: 'Nouvelle Commande',
                conceled: 'Commande Annulée',
                proposal_sent: 'Propostion Envoyée',
                accepted: 'Course Acceptée',
                started: 'Course Démarrée',
                pickedup: 'Commande Récupérée',
                shipped: 'Commande Livrée',
                reception_confirmed: 'Réception Confirmée',
            },
            when: {
                now: 'Course Urgente',
                future: 'Course Planifiée',
            },
            vehicule_types: {
                remorque: 'Remorque',
                honda: 'Honda',
                stafette: 'Stafette',
            },
            payment_modes: {
                cash: 'Cash',
                online: 'En ligne',
            },
        },
        slides: {
            name: 'Slide |||| Slides',
            fields: {
                name: 'Nom',
                image: 'Image',
                is_enabled: 'Est activée',
                created_at: 'Date de Création',
            },
        },
        vehicule_types: {
            benne: 'Remorque Benne', // Remorque Benne
            remorque: 'Remorque Plateau', // Remorque Plateau
            honda: 'Honda', // Honda
            stafette: 'Estafette', // Estafette
        },
    },
};

export default customFrenchMessages;
